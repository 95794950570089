
import {defineComponent, onMounted,watch} from "vue";
import LogoutIcon from "@/components/system/LogoutIcon.vue";
import TimetableTitle from "@/components/timetable/TimetableTitle.vue";
import {Row, Col, Card, Avatar, Empty} from 'ant-design-vue'
import {checkStudentBill} from "@/views/timetable/myTimetable/CheckStudent/CheckStudent";
import GotoLesson from "../../../../components/timetable/GotoLesson.vue";
import DwAvatar from "@/components/system/DwAvatar.vue";
import TimetableCheckbox from "@/components/timetable/TimetableCheckbox.vue";
import CloseIcon from "@/components/system/CloseIcon.vue";

export default defineComponent({
  name: "CheckStudent",
  setup(){
    const {
      onCheckChange,
      onSendChange,
      onConfirm,
      onRefresh,
      init,
      refData,
      comClassTime,
    } = checkStudentBill();

    onMounted(async ()=>{
      await init()
    })

    return {
      ...refData,
      comClassTime,
      onCheckChange,
      onSendChange,
      onConfirm,
      onRefresh,
    }
  },
  components: {
    CloseIcon,
    TimetableCheckbox,
    DwAvatar,
    GotoLesson,
    TimetableTitle,
    LogoutIcon,
    ARow: Row,
    ACol: Col,
    ACard: Card,
    ACardMeta: Card.Meta,
    AAvatar: Avatar,
    AEmpty: Empty,
  },
  data() {
    return {}
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {

    },
  },
})
