import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "check-student" }
const _hoisted_2 = { class: "check-student__topicname" }
const _hoisted_3 = { class: "check-student__classtime" }
const _hoisted_4 = { class: "check-student__cardcontent scroll-bar-hidden" }
const _hoisted_5 = { class: "check-student__cardname" }
const _hoisted_6 = { class: "check-student__btns" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_close_icon = _resolveComponent("close-icon")!
  const _component_timetable_title = _resolveComponent("timetable-title")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_empty = _resolveComponent("a-empty")!
  const _component_dw_avatar = _resolveComponent("dw-avatar")!
  const _component_timetable_checkbox = _resolveComponent("timetable-checkbox")!
  const _component_a_card_meta = _resolveComponent("a-card-meta")!
  const _component_a_card = _resolveComponent("a-card")!
  const _component_goto_lesson = _resolveComponent("goto-lesson")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_component_close_icon),
      _createVNode(_component_timetable_title, null, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createTextVNode(" 上课点名 ")
        ])),
        _: 1
      })
    ]),
    _createVNode(_component_a_row, { style: {"margin-top":"20px","margin-bottom":"20px"} }, {
      default: _withCtx(() => [
        _createVNode(_component_a_col, { span: 12 }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_2, " 第" + _toDisplayString(_ctx.classTimes) + "次课：" + _toDisplayString(_ctx.topicName), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_a_col, {
          span: 12,
          style: {"text-align":"right"}
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_3, " 上课时间：" + _toDisplayString(_ctx.comClassTime), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.studentList.length===0)
        ? (_openBlock(), _createBlock(_component_a_empty, {
            key: 0,
            image: require('@/assets/image/timetable/timetable-nostu.png'),
            "image-style": {
              height: '250px',
            }
          }, {
            description: _withCtx(() => _cache[3] || (_cache[3] = [
              _createElementVNode("div", {
                class: "empty-no__text",
                style: {"color":"#FFF"}
              }, " 暂无入班学生哦~ ", -1)
            ])),
            _: 1
          }, 8, ["image"]))
        : (_openBlock(), _createBlock(_component_a_row, {
            key: 1,
            type: "flex",
            align: "middle",
            gutter: [20, 30]
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.studentList, (i) => {
                return (_openBlock(), _createBlock(_component_a_col, {
                  span: 4,
                  key: i.studentId
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_card, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_card_meta, { style: {"text-align":"center"} }, {
                          description: _withCtx(() => [
                            _createVNode(_component_dw_avatar, {
                              img: i.avatar,
                              sex: i.sex,
                              size: 117
                            }, null, 8, ["img", "sex"]),
                            _createElementVNode("div", _hoisted_5, [
                              _createVNode(_component_timetable_checkbox, {
                                onChange: _ctx.onCheckChange,
                                value: i.check,
                                "onUpdate:value": ($event: any) => ((i.check) = $event),
                                disabled: i.isChecked
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(i.studentName), 1)
                                ]),
                                _: 2
                              }, 1032, ["onChange", "value", "onUpdate:value", "disabled"])
                            ]),
                            _createElementVNode("div", {
                              class: "check-student__cardsent",
                              style: _normalizeStyle({background: i.courseIndex? '': '#F17C26'})
                            }, [
                              (!i.courseIndex)
                                ? (_openBlock(), _createBlock(_component_timetable_checkbox, {
                                    key: 0,
                                    value: i.giveout,
                                    "onUpdate:value": ($event: any) => ((i.giveout) = $event)
                                  }, {
                                    default: _withCtx(() => _cache[4] || (_cache[4] = [
                                      _createTextVNode("发放教材")
                                    ])),
                                    _: 2
                                  }, 1032, ["value", "onUpdate:value"]))
                                : _createCommentVNode("", true)
                            ], 4)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }))
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", null, [
        _createElementVNode("div", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onConfirm && _ctx.onConfirm(...args))),
          class: _normalizeClass({active:_ctx.isBut})
        }, "确认考勤", 2),
        _createElementVNode("div", {
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onRefresh && _ctx.onRefresh(...args)))
        }, "刷新")
      ])
    ]),
    _createVNode(_component_goto_lesson)
  ]))
}